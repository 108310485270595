<template>
    <VueSlickCarousel class="card-tariff-row" v-bind="slickOptions">
        <template v-if="tariffs.length > 0">
            <div class="card-tariff" v-for="tariff of tariffs" :key="tariff.id">
                <div :class="[isCurrentTariff(tariff) ? 'card-tariff-banner__wrapper' : 'card-tariff__wrapper']">
                    <div v-if="isCurrentTariff(tariff)">
                        <div class="card-tariff__banner">
                            <span class="card-tariff-current__banner" data-tooltip="Тарифный план не будет переключен.">Текущий ТП</span>
                        </div>
                    </div>

                    <div class="card-tariff__head">
                        <div class="card-tariff__head-title" v-html="tariff.name"></div>
                    </div>

                    <div class="card-tariff__footer">
                        <p v-if="tariff.subscriptionFee">{{tariff.subscriptionFee}} ₽ за 30 дней</p>
                        <p>{{ tariff.price }} ₽ за 15 дней</p>

                        <div class="input-radio-tariff">


                            <button :key="1" v-if="value !== null && value.id === tariff.id" class="btn">Выбран</button>
                            <button :key="2" v-else class="btn back" @click="onChange(tariff)">Выбрать</button>

                            <!--                    <input type="radio"-->
                            <!--                           v-model="tariffsRadio"-->
                            <!--                           name="tariffsRadio"-->
                            <!--                           :id="`tariffs_radio_${tariff.id}`"-->
                            <!--                           :value="tariff.id"-->
                            <!--                           @change="onChange(tariff)"-->
                            <!--                    >-->
                            <!--                    <label :key="1" v-if="value !== null && value.id === tariff.id" :for="`tariffs_radio_${tariff.id}`" class="btn" onclick="">Выбран</label>-->
                            <!--                    <label :key="2" v-else :for="`tariffs_radio_${tariff.id}`" class="btn back" onclick="">Выбрать</label>-->
                        </div>

                    </div>
                </div>
            </div>
        </template>

    </VueSlickCarousel>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapGetters} from "vuex";

export default {
    name: "CardTariff",
    components: {
        VueSlickCarousel
    },
    props: ['tariffs', 'value'],
    watch: {
        value(newVal) {
            this.value = newVal
        },
    },
    data() {
        return {
            tariffsRadio: '',
            slickOptions: {
                slidesToShow: 3,
                arrows: true,
                dots: true,
                draggable: false,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            draggable: true,
                            centerMode: true,
                            infinite: false,
                            dots: true
                        }
                    }
                ]
            }
        }
    },
    computed: {
        ...mapGetters('OEveryWhere/clientInfo', ['clientInfo', 'currentProducts']),
    },
    methods: {
        onChange(tariff) {
            this.$emit('input', tariff)
        },
        isCurrentTariff(tariff) {
            return tariff.id === this.clientInfo.productId
        },
    },
    mounted() {
        console.log("card tariff mounted")
    }
}
</script>

<style lang="scss">

    .modal-message {
        line-height: 20px;
        margin-bottom: 50px;

        a {
            color: var(--mf-blue);
        }
    }

    .input-radio-tariff {
        input {
            display: none;
        }

        .input-radio-tariff__spacer {
            height: 32px;
        }

        label {
            width: 100%;
        }
    }

    .card-tariff-row {
        display: flex;
        margin: 0 -10px 25px;
    }

    .card-tariff-row {
        margin-top: -22px;
    }

    .slick-track {
        display: flex !important;
    }

    .slick-initialized .slick-slide {
        height: auto;

        > div {
            height: 100%;
        }
    }

    .slick-prev, .slick-next {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        background: url('../../assets/svg/arrow_green_right.svg') center / 12px no-repeat;

        &:hover, &:focus {
            background: url('../../assets/svg/arrow_green_right.svg') center / 12px no-repeat;
        }
    }

    .slick-prev {
        transform: rotate(180deg);
    }

    .slick-prev::before, .slick-next::before {
        content: "";
    }

    .slick-dots {
        bottom: -50px;

        *, li button:before {
            width: 12px !important;
            margin: 0 !important;
        }

        li button:before {
            opacity: 1;
            color: var(--mf-gray);
            font-size: 8px;
        }

        li.slick-active button:before {
            opacity: 1;
            font-size: 12px;
            color: var(--mf-green);
        }
    }

    .card-tariff {
        height: 100%;
        padding: 22px 10px 0 !important;

        .card-tariff__wrapper {
            border: 1px solid #D9D9D9;
            padding: 11px 18px 26px;
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        &.card-tariff__has_products .card-tariff-banner__wrapper {
            background-color: initial;
        }

        .card-tariff-banner__wrapper {
            position: relative;
            border: 1px solid #D9D9D9;
            background-color: var(--mf-green-light);
            padding: 11px 18px 26px;
            height: 100%;
            display: flex;
            flex-direction: column;

            .card-tariff__banner {
                position: absolute;
                cursor: default;
                transition: .2s;
                display: block;
                top: -11px;
                right: -90px;
            }

            .card-tariff-current__banner {
                display: inline-block;
                padding: 3px 8px;
                color: #fff;
                background-color: var(--mf-red);
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                transform: rotate(-6deg);
                text-align: left;
                cursor: pointer;
                transition: .2s;
            }

            [data-tooltip] {
                position: relative;
            }
            [data-tooltip]::after {
                content: attr(data-tooltip);
                position: absolute;
                width: 270px;
                left: 0; top: 0;
                background: #fff;
                color: #000;
                padding: 0.5em;
                font-weight: normal;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
                pointer-events: none;
                opacity: 0;
                transition: 1s;
                transform: rotate(+6deg);
            }
            [data-tooltip]:hover::after {
                opacity: 1;
                top: 2em;
                left: -12em;
            }
        }

        .card-tariff__head {
            text-align: center;
            line-height: 25px;
            flex-grow: 1;
        }

        .card-tariff__head-title {
            font-size: 19px;
            font-weight: 500;
        }

        .card-tariff__recommend {
            font-size: 12px;
        }

        .card-tariff__body {
            margin-bottom: 30px;
        }

        .card-tariff__body-block {
            margin-bottom: 14px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .card-tariff__body-title {
            color: var(--mf-gray);
            line-height: 20px;
            margin-bottom: 2px;
        }

        .card-tariff__body-text, p {
            line-height: 20px;
            font-weight: 500;
        }

        .card-tariff__footer {
            p {
                font-size: 19px;
                line-height: 20px;
                margin-bottom: 7px;
                text-align: center;
            }

            .btn {
                justify-content: center;
                width: 100%;
                height: 32px;
            }
        }
    }

    .discount-tariff__row {
        display: flex;
        align-items: center;
        justify-content: normal;
        line-height: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        input {
            height: 20px;
            margin-right: 20px;
        }

        label {
            cursor: pointer;
        }
    }

    .switch.small {
        align-self: flex-start;
        width: 35px;
        height: 19px;
        margin-right: 10px;

        span:before {
            height: 15px;
            width: 15px;
            background: #fff url(/new/img/3-line.c2937a85.svg) 3px 50%/7px no-repeat;
        }

        input:checked + span:before {
            transform: translateX(15px);
            background: #fff url(/new/img/3-line.c2937a85.svg) 3px 50%/7px no-repeat;
        }

        input.disabled + span:before {
            transform: none;
        }

        input.disabled + span.round {
            background-color: #B2B2B2;
        }
    }

    @media (max-width: 767px) {

        .card-tariff__footer .btn.back:hover { // фикс для телефонов чтоб не срабатывал hover при быстром нажатии на 2 кнопки
            background-color: #fff;
            color: var(--mf-green);
            border: 1px solid var(--mf-green);
        }

        .card-tariff-row {
            display: flex;
            margin: 0 -15px 25px;
        }

        .slick-list {
            padding: 0 50px 0 5px !important;
        }

        .card-tariff-row {
            margin-bottom: 75px;
        }
    }
</style>