<template>
  <div class="table-orders__row">
    <p :style="{display: hide(342)}">
      <img v-if='request.project_id === 2' src="../assets/svg/client_mf.svg" alt="img"
           width="24">
      <img v-else-if='request.project_id === 1' src="../assets/svg/client_wf.svg"
           alt="img" width="24">
    </p>
    <p>{{ request.sd_registrationdate }}</p>
    <p>{{ request.gm_ccmpguid }} / {{ request.sd_number }}</p>
    <p :style="{display: hide(767)}">{{ request.sd_substatus }}</p>
    <p :style="{display: hide(810)}">
            <span class="table-orders__row-building">
              <img v-if='request.gm_privatedistrict' src="../assets/svg/home-group.svg" alt="img" width="24" >
              <img v-else src="../assets/svg/office-building-outline.svg" alt="img" width="24">
            </span>
      {{ request.gm_region }}
    </p>
    <p :style="{display: hide(965)}">{{ request.gm_userloginnbnd }}</p>
    <p :style="{display: hide(1110)}">{{ request.gm_localscheduled }}</p>
    <p :style="{display: hide(1110)}">{{ request.gm_suspendtoclient }}</p>
    <button title="Подробнее" class="table-orders__search"
            @click.prevent="showDesc(request)" :key="'btn' + request.incidentId">
    </button>
    <ModalOrderInfo
        :request="modalData"
        :guid="request.incidentId"
        :modal-id="modalId"
    />
  </div>
</template>

<script>
import ModalOrderInfo from "@/components/modals/requestFMC/ModalOrderInfo.vue";
import multiModals from "@/mixins/multiModals";
import resizeWin from "@/mixins/resizeWin";
import {mapActions} from "vuex";
import processItem from "@/mixins/processItem";

export default  {
  name: "OrderInfoElement",
  components: {ModalOrderInfo},
  props: {
    dataRequest: {
      type: Object,
      required: true,
    },
    modalId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      request: this.dataRequest,
      modalData: {},
    }
  },
  mixins: [resizeWin, processItem, multiModals],
  methods: {
    ...mapActions('CRM', ['doGetDemandInfo']),
    async showDesc() {
      var dataRequest = this.dataRequest

      let incidentId = this.request.incidentId
      if (this.request.project_id === 2) {
        incidentId = this.request.gm_ccmpguid
      }

      const item = await this.doGetDemandInfo({
        incident: incidentId,
        project: this.request.project_id,
      })
          .then(r => r.data ?? {})

      dataRequest = this.processFoundedItem(item)

      this.modalData = dataRequest

      this.$bvModal.show(this.generateId('modalOrderInfo'))
    },
  }
}
</script>

<style scoped lang="scss">
.table-orders__row {
  p {
    display: flex;
    align-items: center;
    padding-right: 20px;
    flex-shrink: 0;
  }
}

.table-orders__header,
.table-orders__row  {
  p:first-child {
    width: 40px;
  }

  p:nth-child(2) {
    width: 140px;
  }

  p:nth-child(3) {
    width: 110px;
  }

  p:nth-child(4) {
    width: 150px;
  }

  p:nth-child(5) {
    width: 140px;
  }

  p:nth-child(6) {
    width: 120px;
  }

  p:nth-child(7) {
    width: 110px;
  }

  p:nth-child(8) {
    width: 110px;
  }
}

.table-orders__body-desc {
  position: relative;
  background-color: #F7F7F7;
  border-top: 1px solid #D9D9D9;
  padding: 25px 25px 30px 70px;
  line-height: 18px;
  word-break: break-word;

  .table-orders__row {
    display: flex;
    margin-bottom: 23px;
  }

  .btn {
    //margin-left: -8px;
  }

  .table-orders__row-building {
    padding-right: 10px;
    opacity: 0.3;
  }
}

.table-orders__search {
  position: absolute;
  right: 10px;
  width: 24px;
  height: 24px;
  background: url('../assets/svg/Search_24.svg') center / 22px no-repeat;
  cursor: pointer;
}

.table-orders__body-head.not-collapsed {
  .table-orders__arrow {
    transform: rotate(-90deg);
  }
}

@media (max-width: 767px) {
  .table-orders__header,
  .table-orders__body-head {

  }

  .table-orders__body-desc {
    padding: 25px 15px 30px;
  }

  .table-orders__header p:first-child {
    width: 30px;
    padding-right: 5px;
  }

  .table-orders__header p:nth-child(2) {
    width: 49%;
  }

  .table-orders__row p:first-child {
    width: 30px;
    padding-right: 5px;
  }

  .table-orders__row p:nth-child(2) {
    width: 49%;
  }

  .table-orders__body-desc .table-tariff__row {
    margin-bottom: 22px;
  }

  .table-orders__arrow {
    right: 7px;
  }
}
</style>